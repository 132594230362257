.module-grid {
    position: relative;
    margin: 0 auto;
    max-width: 1100px;
    color: #000;
    width: 100%;
    overflow: hidden;

    .with--hover {
        position: relative;

        .grid-item-wrapper {
            overflow: hidden;
            position: relative;
        }

        .hover-default {
            display: block;

            video {
                max-width: 100%;
                display: block;
            }
        }

        .hover-hover {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 0.2s ease-in;

            img,
            video {
                width: 100%;
                display: block;
            }
        }

        @media(min-width: 768px) {
            &:hover {
                .hover-hover {
                    opacity: 1;
                }
            }
        }
    }

    .grid-item-wrapper {
        overflow: hidden;
        display: block;
        margin-bottom: 0 !important;

        video {
            max-width: 100%;
            display: block;
        }
    }

    .editor-content {
        margin-top: unset;
        text-align: left;
    }

    .title-wrap {

        p, h1, h2 {
            margin: 0;
        }

        h1.linebreak-true,
        h2.linebreak-true,
        h3.linebreak-true,
        p.linebreak-true {
            margin: 0;
        }

        p {
            &.ql-align-justify {
                text-align: justify;
            }

            &.ql-align-center {
                text-align: center;
            }

            &.ql-align-right {
                text-align: right;
            }
        }
    }

    &.full--1 {
        max-width: 100%;

        .col-12 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    .module_grid__text {
        margin: 0 0 30px;
    }

    .masonry-brick {
        padding: 15px;
        margin: 0;

        img {
            max-width: 100%;
        }
    }

    .images {
        clear: both;
        overflow: hidden;
        list-style: none;
        margin: 0 -15px 20px;
        padding: 0;
        width: 100%;

        @media(max-width: 992px) {
            margin-bottom: 0;
        }

        .equal {
            margin-bottom: 30px;
        }

        img {
          max-width: 100%;

          &.maxHeight {
            max-height: 240px;
          }
        }

        .image__image {
            position: relative;
            text-align: center;

            .actions {
                position: absolute;
                right: 15px;
                bottom: 15px;

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        display: inline-block;
                        margin-left: 15px;
                        background: #eee;
                        width: 32px;
                        border-radius: 32px;
                        text-align: center;
                        height: 32px;
                        line-height: 30px;
                        cursor: pointer;

                        img {
                            width: 14px;
                            margin: 0 !important;
                            max-width: 100% !important;
                            cursor: pointer !important;
                            transition: opacity 0.2s ease-in;
                        }

                        &:hover {
                            img {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }

            .buttons {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
                z-index: 1;
                opacity: 0;

                ul {
                    list-style: none;
                    position: absolute;
                    bottom: 10px;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0;
                    padding: 0;
                    width: calc(100% - 90px);
                    overflow: hidden;

                    li {
                        display: inline-block;
                        margin: 0 10px;
                        height: 38px;
                        line-height: 38px;
                        padding: 0 24px;
                        background: #fff;
                        border-radius: 5px;
                        color: #000;
                        font-size: 13px;
                        border: none;
                        cursor: pointer;
                        float: none;

                        &:hover {
                            background: #0c56ff;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .image__title {
            position: relative;
            padding-top: 30px;
            text-align: center;
            min-height: 100px;

            @media(max-width: 992px) {
                padding-top: 20px;
                min-height: 70px;
            }

            img {
                position: absolute;
                top: 38px;
                left: 15px;

                @media(max-width: 992px) {
                    top: 24px;
                }
            }

            span {
                display: block;
                color: #000;
                font-size: 18px;
                font-weight: 400;
                line-height: 28px;
                padding: 0 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .ng-inline-edit__input {
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 28px;
                    margin: 0;
                    width: calc(100% - 50px);
                    font-family: "Rubik";
                }

                .ng-inline-edit__text {
                    padding: 0;
                }
            }

            &.image__title--no-icons {
                img {
                    display: none;
                }

                span {
                    padding: 0 10px;

                    .ng-inline-edit__input {
                        width: calc(100% - 20px);
                    }
                }
            }
        }
    }

    .module-grid__image {
        border-radius: 5px;
        border: 1px dashed #b1b8c1;
        position: relative;
        text-align: center;
        min-height: 70px;
        margin-bottom: 30px;
        cursor: pointer;

        @media(max-width: 992px) {
            min-height: 100px;
        }

        &:hover {
            border-color: #0c56ff;
        }

        &.no-border {
            border: none;
        }
    }

    @media (min-width: 768px) {
        &--3 {
            .col-sm-3:nth-child(4n+1) {
              clear: left;
            }
        }

        &--4 {
            .col-sm-4:nth-child(3n+1) {
              clear: left;
            }
        }

        &--6 {
            .col-sm-6:nth-child(2n+1) {
              clear: left;
            }
        }
    }

    &.column-padding-0 {
        //padding-left: 15px;
        //padding-right: 15px;

        .images {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }

        .masonry-brick {
            padding: 0;
        }

        .module-grid__image {
            margin-top: 30px !important;
        }
    }


    /* Masonry grid */
    .masonry {
      transition: all .5s ease-in-out;
      column-gap: 30px;
      column-fill: initial;
    }

    /* Masonry item */
    .masonry .brick {
      margin-bottom: 30px;
      //display: inline-block; /* Fix the misalignment of items */
      vertical-align: top; /* Keep the item on the very top */

      img {
        max-width: 100%;
      }

      .image__image {
          position: relative;
          text-align: center;

          .actions {
              position: absolute;
              right: 15px;
              bottom: 15px;

              ul {
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  li {
                      display: inline-block;
                      margin-left: 15px;
                      background: #eee;
                      width: 32px;
                      border-radius: 32px;
                      text-align: center;
                      height: 32px;
                      line-height: 30px;
                      cursor: pointer;

                      img {
                          width: 14px;
                          margin: 0 !important;
                          max-width: 100% !important;
                          cursor: pointer !important;
                          transition: opacity 0.2s ease-in;
                      }

                      &:hover {
                          img {
                              opacity: 0.7;
                          }
                      }
                  }
              }
          }

          .buttons {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.6);
              z-index: 1;
              opacity: 0;

              ul {
                  list-style: none;
                  position: absolute;
                  bottom: 10px;
                  left: 50%;
                  transform: translateX(-50%);
                  margin: 0;
                  padding: 0;
                  width: calc(100% - 90px);
                  overflow: hidden;

                  li {
                      display: inline-block;
                      margin: 0 10px;
                      height: 38px;
                      line-height: 38px;
                      padding: 0 24px;
                      background: #fff;
                      border-radius: 5px;
                      color: #000;
                      font-size: 13px;
                      border: none;
                      cursor: pointer;
                      float: none;

                      &:hover {
                          background: #0c56ff;
                          color: #fff;
                      }
                  }
              }
          }
      }
    }

    /* Gutterless masonry */
    .masonry.gutterless {
      column-gap: 0;
    }

    .masonry.gutterless .brick {
      margin-bottom: 0;
    }
}

.module--grid {
    &.module--grey {
        .module-grid__image {
            margin-bottom: 40px;
        }
    }

    &.module--nopadding,
    &.module--nobottom {
        .module-grid__image {
            margin-top: 30px;
        }

        .images {
            margin-bottom: -10px;
        }
    }
}

.module-grid--1_6,
.module-grid--1_7,
.module-grid--1_8,
.module-grid--1_9 {
  .corebook-grid {
    .brick {
      width: 33.3333%;
    }
  }
}

.corebook-grid {
  margin-left: -15px;
  margin-right: -15px;

  .brick {
      width: 50%;
      padding-bottom: 0;

      &:hover {
        transform: none !important;
      }

      img {
          width: 100%;
          height: auto;
      }

      .image__image {
          position: relative;
          text-align: center;

          .actions {
              position: absolute;
              right: 30px;
              bottom: 15px;

              ul {
                  list-style: none;
                  margin: 0;
                  padding: 0;

                  li {
                      display: inline-block;
                      margin-left: 15px;
                      background: #eee;
                      width: 32px;
                      border-radius: 32px;
                      text-align: center;
                      height: 32px;
                      line-height: 30px;
                      cursor: pointer;

                      img {
                          width: 14px;
                          margin: 0 !important;
                          max-width: 100% !important;
                          cursor: pointer !important;
                          transition: opacity 0.2s ease-in;
                      }

                      &:hover {
                          img {
                              opacity: 0.7;
                          }
                      }
                  }
              }
          }

          .buttons {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.6);
              z-index: 1;
              opacity: 0;

              ul {
                  list-style: none;
                  position: absolute;
                  bottom: 10px;
                  left: 50%;
                  transform: translateX(-50%);
                  margin: 0;
                  padding: 0;
                  width: calc(100% - 90px);
                  overflow: hidden;

                  li {
                      display: inline-block;
                      margin: 0 10px;
                      height: 38px;
                      line-height: 38px;
                      padding: 0 24px;
                      background: #fff;
                      border-radius: 5px;
                      color: #000;
                      font-size: 13px;
                      border: none;
                      cursor: pointer;
                      float: none;

                      &:hover {
                          background: #0c56ff;
                          color: #fff;
                      }
                  }
              }
          }
      }
  }

  &.gutterless {
    margin-left: 0;
    margin-right: 0;

    .brick {
      padding-bottom: 0;

      img {
        margin: 0;
        max-width: 100% !important;
      }

      .actions {
        right: 15px;
      }
    }
  }
}

.doDontModule {
    .module-grid .images .image__title {
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        textarea {
            resize: none;
            border: none;
            box-shadow: none;
            font-size: unset;
            font-weight: unset;
            line-height: unset;
            padding: 0;
            white-space: pre-wrap;
            word-wrap: break-word;
            overflow: hidden;
            font-family: unset;
        }

        span {
            font-size: unset;
            font-weight: unset;
            line-height: unset;
            padding: 0;
            white-space: pre-wrap;
            word-wrap: break-word;
            text-align: left;
            word-break: break-word;
        }
    }

    .module-grid .images .image__title img {
        position: relative;
        top: 6px;
        left: 0;
        margin-right: 10px;
    }
}


@media(min-width: 992px) {
  .module-grid--1_5 {
    .corebook-grid {
      .brick {
        width: 20%;
      }
    }
  }

  .module-grid--1_6 {
    .corebook-grid {
      .brick {
        width: 16.67%;
      }
    }
  }

  .module-grid--1_7 {
    .corebook-grid {
      .brick {
        width: 14.29%;
      }
    }
  }

  .module-grid--1_8 {
    .corebook-grid {
      .brick {
        width: 12.5%;
      }
    }
  }

  .module-grid--1_9 {
    .corebook-grid {
      .brick {
        width: 11.11%;
      }
    }
  }
  
  .module-grid--4 {
    .corebook-grid {
      .brick {
        width: 33.3333%;
      }
    }
  }

  .module-grid--3 {
    .corebook-grid {
      .brick {
        width: 25%;
      }
    }
  }
}

.GridModule .videoSlide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .videoSlideInner {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        video {
            max-height: 90%;
            max-width: 90%;
            height: auto;
            width: auto;
            display: block;
            margin: 0 auto;
        }
    }
}

